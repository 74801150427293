var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { pale: _vm.model.disabled } }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("GenInput", {
            attrs: {
              size: 2,
              "label-size": 1,
              entity: "custom-service",
              attr: "disabled",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.disabled,
              callback: function($$v) {
                _vm.$set(_vm.model, "disabled", $$v)
              },
              expression: "model.disabled"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("GenInput", {
            attrs: {
              size: 11,
              "label-size": 1,
              entity: "custom-service",
              attr: "description",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.description,
              callback: function($$v) {
                _vm.$set(_vm.model, "description", $$v)
              },
              expression: "model.description"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { attrs: { claas: "row" } }, [
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("GenInput", {
            attrs: {
              size: 6,
              "label-size": 3,
              entity: "custom-service",
              attr: "daily",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.daily,
              callback: function($$v) {
                _vm.$set(_vm.model, "daily", $$v)
              },
              expression: "model.daily"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("GenInput", {
            attrs: {
              size: 6,
              "label-size": 3,
              entity: "custom-service",
              attr: "monthly",
              apiDomain: _vm.apiDomain,
              apiTopic: "client",
              apiPath: _vm.apiPath
            },
            model: {
              value: _vm.model.monthly,
              callback: function($$v) {
                _vm.$set(_vm.model, "monthly", $$v)
              },
              expression: "model.monthly"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("br"),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "well",
            staticStyle: { "box-shadow": "rgba(0, 0, 0, 0.75) 0px 5px 15px" }
          },
          [
            _c("DynaList", {
              attrs: {
                apiDomain: _vm.apiDomain,
                apiTopic: "client",
                baseUrl: _vm.linkEntity,
                addText: "Добавить подключение"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(data) {
                    return [
                      _c("CustomLink", {
                        attrs: {
                          rec: data.parent,
                          apiDomain: _vm.apiDomain,
                          apiTopic: "client",
                          apiPath: data.parent.entity
                        }
                      })
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }