<template>
  <div>
          <div class="row">
            <div class="col-12">
              <GenInput :size=10 :label-size=2 entity="custom-service-link" attr=description v-model="model.description" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <GenInput :size=10 :label-size=2 entity="custom-service-link" attr=address v-model="model.address" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <GenInput :size=4 :label-size=1 entity="custom-service-link" attr=latitude v-model="model.latitude" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
            <div class="col-6">
              <GenInput :size=4 :label-size=1 entity="custom-service-link" attr=longitude v-model="model.longitude" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Icon name="globe" @click=showGeoMap v-show="globeVisible" />
          </div>
          <div class="row">
            <div class="col-md-6">
              <GenInput :size=4 :label-size=2 entity="custom-service-link" attr=network v-model="model.network" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
            <div class="col-md6">
              <GenInput :size=4 :label-size=2 entity="custom-service-link" attr=vlan v-model="model.vlan" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
          </div>
  </div>
</template>

<script>
import GenInput from "common/visual/cooper/GenInput.vue"
import * as dialog from "common/dialog-bs/dialog"

export default {
  
  name: "CustomLink",
  
  components: {GenInput},
  
  props: {
    rec: Object,
    apiDomain: String,
    apiTopic: String,
    apiPath: String,
  },
  
  data()
  {
    return {
      model: {},
    }
  },
  
  mounted()
  {
    this.model = this.rec
  },

  methods: {
    async showGeoMap()
    {
      await dialog.showComponent("client/corpserv/GeoPoint", "Точка подключения", { latitude: this.model.latitude, longitude: this.model.longitude })
    }
  },
  
  computed: {
    globeVisible()
    {
      return this.model.latitude && this.model.longitude
    }
  },
}

</script>

<style scoped>
</style>
