<template>
  <div :class="{pale: model.disabled}">
          <div class="row">
            <div class="col-md-12">
              <GenInput :size=2 :label-size=1 entity="custom-service" attr=disabled v-model="model.disabled" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <GenInput :size=11 :label-size=1 entity="custom-service" attr=description v-model="model.description" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
          </div>
          <div claas="row">
            <div class="col-md-6">
              <GenInput :size=6 :label-size=3 entity="custom-service" attr=daily v-model="model.daily" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
            <div class="col-md-6">
              <GenInput :size=6 :label-size=3 entity="custom-service" attr=monthly v-model="model.monthly" :apiDomain=apiDomain apiTopic=client :apiPath=apiPath />
            </div>
          </div>
          <div class="row">
          <div class="col-md-12">
          <br/>
          <div class="well" style="box-shadow: rgba(0, 0, 0, 0.75) 0px 5px 15px;">
            <DynaList :apiDomain=apiDomain apiTopic=client :baseUrl=linkEntity addText="Добавить подключение" >
              <template v-slot="data">
                <CustomLink :rec=data.parent :apiDomain=apiDomain apiTopic="client" :apiPath=data.parent.entity />
              </template>
            </DynaList>
          </div>
          </div>
          </div>
  </div>
</template>

<script>
import GenInput from "common/visual/cooper/GenInput.vue"
import CustomLink from "client/corpserv/CustomLink.vue"
import DynaList from "common/visual/DynaList.vue"

export default {
  
  name: "CustomService",
  
  components: {GenInput, CustomLink, DynaList},
  
  props: {
    rec: Object,
    apiDomain: String,
    apiTopic: String,
    apiPath: String,
  },
  
  data()
  {
    return {
      model: {},
    }
  },
  
  mounted()
  {
    this.model = this.rec
  },
  
  computed: {
    linkEntity()
    {
      if (!this.model.entity) return null
      return this.model.entity.replace(/\/client\/\d+/, "") + "/link"
    }
  },
  
}

</script>

<style scoped>
.pale {
  opacity: 0.5;
}
</style>
