var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("h4", [_vm._v("Контакты")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-12" },
      [_c("ContactList", { attrs: { uid: _vm.state.uid } })],
      1
    ),
    _vm._v(" "),
    _c("h4", [_vm._v("Сервисы")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-md-12" },
      [_c("ServList", { attrs: { uid: _vm.state.uid } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }