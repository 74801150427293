var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "l-map",
    {
      ref: "myMap",
      staticStyle: { height: "60vh", width: "60vh", "z-index": "9" },
      attrs: {
        zoom: _vm.zoom,
        center: _vm.center,
        options: { preferCanvas: true }
      }
    },
    [
      _c("l-tile-layer", { attrs: { url: _vm.url } }),
      _vm._v(" "),
      _c("l-marker", {
        attrs: { "lat-lng": [_vm.state.latitude, _vm.state.longitude] }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }